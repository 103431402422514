/// converts usecase [ScrollableTabsConfig] json to a building-blocks-tabs tab json
export function usecaseTabConvertor(usecaseJson) {
    var _usecaseJson_asset, _usecaseJson_asset1;
    return {
        tabLabel: usecaseJson.label,
        tabIcon: usecaseJson.iconAsset,
        textAndAsset: {
            contentClassName: "",
            swapTextAndAssetPosition: true,
            hideParagraph: true,
            leftSizeWidth: null,
            rightSizeWidth: null,
            background: {},
            fullParagraph: {
                topic: {},
                title: "",
                body: "",
                textAlign: "",
                mobileTextAlign: null,
                titleColor: ""
            },
            asset: {
                position: "",
                fitToContainer: true,
                image: (_usecaseJson_asset = usecaseJson.asset) === null || _usecaseJson_asset === void 0 ? void 0 : _usecaseJson_asset.image,
                video: (_usecaseJson_asset1 = usecaseJson.asset) === null || _usecaseJson_asset1 === void 0 ? void 0 : _usecaseJson_asset1.video,
                useWindowMask: false
            },
            assetQuality: "",
            withDynamicParagraphTitle: false,
            withDynamicParagraphSubtitle: false,
            paddingTop: "",
            paddingBottom: "",
            titleSize: "",
            textSize: "",
            nestedBoardItems: {
                addLiveBoardTextAndAsset: false,
                boardBannerText: "",
                embeddedBoardTitle: "",
                addSecondLiveBoardSignUpButton: false
            }
        }
    };
}
