import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
export const StyledUsecasesGalleryTabsDesktopComponent = styled.div`
  .usecases-gallery-tabs-desktop-component {
    ${({ maxWidth })=>maxWidth && `max-width: ${maxWidth}px;`}
    margin: auto;
    padding: 0 32px;
    overflow: hidden;

    &.align-to-logo {
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING};
      padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING};
    }
  }
`;
