import styled from "@emotion/styled";
import colors from "styles/colors";
export const StyledMobileTabNavButtonWithIcon = styled.div`
  ${({ selectedIconBackgroundColor, isSelected })=>`
    width: 80px;
    height: 100px;
    padding: 7px 16px 9px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    .icon-container {
      padding: 10px;
      margin-bottom: 12px;
      opacity: ${isSelected ? 1 : 0.5};
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background-color: ${isSelected ? colors[selectedIconBackgroundColor] : "transparent"};
    }

    .label-container {
      font-size: 0.7rem;
      line-height: 140%;
      text-align: center;
      font-weight: 300;
      opacity: ${isSelected ? 1 : 0.5};
    }
  `}
`;
