import styled from "@emotion/styled";
import colors from "styles/colors";
import { GAINSBORO_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import { TAB_WIDTH } from "./scrollable-tabs-consts";
import { LAYER_TWO } from "/constants/z-index";
const SELECTOR_LINE_PADDING = 20;
export const StyledScrollableTabs = styled.div`
  .scrollable-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tabs-menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      align-items: center;
      z-index: ${LAYER_TWO}};

      .arrow-container {
        cursor: pointer;
        stroke-width: 2;
        padding-bottom: 40px;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      .visible-tabs-container {
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
        position: relative;
        height: 120px;

        -webkit-mask-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 7%,
          rgba(0, 0, 0, 1) 93%,
          rgba(0, 0, 0, 0) 100%
        );
        mask-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 7%,
          rgba(0, 0, 0, 1) 93%,
          rgba(0, 0, 0, 0) 100%
        );

        .tabs-container {
          display: flex;
          position: absolute;
          height: 100%;
          left: 0px;
          bottom: 0px;
          z-index: 10;
          transition: all 0.5s ease-in-out;

          .tabs {
            display: flex;
            align-items: flex-end;
            width: fit-content;
            .tab {
              cursor: pointer;
              text-align: center;
              width: ${(props)=>props.tabWidth}px;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 150%; /* 21px */
              letter-spacing: -0.159px;
              padding-top: 4px;
              opacity: 0.6;
              border-bottom: 3px solid transparent;
              transition: border-image 0s ease-in, opacity 0.3s ease-in-out, padding-top 0.3s ease-in-out;

              :hover:not(.selected) {
                opacity: 1;
                padding-top: 0;
              }

              &.selected {
                opacity: 1;
                padding-top: 4px;
                transition: border-image 2s ease-in, opacity 0.3s ease-in-out, padding-top 0.3s ease-in-out;

                .tab-icon-container {
                  background-color: ${(props)=>colors[props.selectedIconBackground]};
                  transition: background-color 0.3s ease-in-out;
                }
              }

              &.with-transition {
                transition: all 0.5s ease-in-out;
              }

              .tab-icon-container {
                padding: 10px;
                display: flex;
                border-radius: 16px;
                justify-content: center;
                align-items: center;
                transition: background-color 0.3s ease-in-out;
              }

              .tab-icon {
                ${({ iconSize })=>iconSize ? `width: ${iconSize}px;` : ""}
              }

              .tab-label {
                height: 100%;
                display: flex;
                padding-top: 10px;
                align-items: top;
              }
            }

            ${({ withDividerLine })=>withDividerLine && `.tab::before {
              opacity: 0;
              content: "";
              position: absolute;
              top: 30px;
              width: 40px;
              height: 40px;
              background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: -webkit-radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              filter: blur(15px);
              transition: opacity 0.5s ease-in-out;
            }

            .tab::after {
              content: "";
              position: absolute;
              opacity: 0;
              width: ${`${TAB_WIDTH - SELECTOR_LINE_PADDING * 2}px`};
              height: 3px;
              bottom: 0px;
              background-color: ${colors[WHITE_COLOR_NAME]};
              transition: opacity 0s ease-in-out;
            }

            .tab.selected::before {
              opacity: 1;
              transition: opacity 0.5s ease-in-out;
            }

            .tab.selected::after {
              opacity: 1;
              transition: opacity 2s ease-in-out;
            }`}
          }
        }

        .tab-selector-line {
          position: absolute;
          bottom: 0px;
          left: var(--selector-line-left);
          width: ${`${TAB_WIDTH}px`};
          height: 3px;
          z-index: 10;
          padding-left: ${`${SELECTOR_LINE_PADDING}px`};
          padding-right: ${`${SELECTOR_LINE_PADDING}px`};

          &.with-transition {
            transition: all 0.3s ease-in-out;
          }

          .tab-selector-line-color {
            background: ${colors[WHITE_COLOR_NAME]};
            height: 100%;
          }
        }
      }
    }

    .tabs-divider-line {
      background-color: ${colors[GAINSBORO_COLOR_NAME]};
      height: 1px;
      width: 100%;
      margin-bottom: 40px;
      -webkit-mask-image: linear-gradient(to right, transparent, black, black, black, black, transparent);
      mask-image: linear-gradient(to right, transparent, black, black, black, black, transparent);
    }

    .tab-content {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      ${({ contentMarginTop })=>contentMarginTop ? `margin-top: ${contentMarginTop}px;` : ""}

      .tab-content-container {
        ${({ tabContentMaxWidth })=>tabContentMaxWidth ? `max-width: ${tabContentMaxWidth};` : ""}
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        &.hidden {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }
  }
`;
