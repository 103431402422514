export const getElementsInsideParent = (elements, parent)=>{
    const parentRect = parent.getBoundingClientRect();
    const elementsInsideParent = [];
    elements.forEach((element)=>{
        const elementRect = element.getBoundingClientRect();
        if (// check if the element is inside (FULLY OR PARTIALY!) the parent's bounding box:
        (elementRect.top <= parentRect.top && elementRect.top >= parentRect.bottom || elementRect.bottom >= parentRect.top && elementRect.bottom <= parentRect.bottom) && (elementRect.left >= parentRect.left && elementRect.left <= parentRect.right || elementRect.right >= parentRect.left && elementRect.right <= parentRect.right)) {
            elementsInsideParent.push(element);
        }
    });
    return elementsInsideParent;
};
